import {http} from './http'

import {DataFromServer, KullaniciDto} from '../lModel/Modeller'
import {
  IAileFert,
  IAileReisiSil,
  IAileSil,
  IAramaSartlari,
  IIncassoOlusturOnizlemeSonuc,
  IIncassoYapim,
  ITopluFatura,
  IUye,
  IUyeBelge,
  IUyeBelgeDosyali,
  IUyeFatura,
  IUyeIletisim,
  IUyeNoIsle,
  IUyeNot,
  IUyeVefat,
} from '../lModel/Interfaceler'
import { IParaAktar } from '../lModel/Interfaceler/IParaAktar'

export const getUye = async (uyeNo: string, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/uyegetir?id=' + uyeNo,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUyeNodanData = async (
  uyeNo: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/uyenodangetir?id=' + uyeNo,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getAileFertleri = async (
  uyeNo: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getailefertleri?uyeNo=' + uyeNo,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getKayitlilar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/kayitlilargetir',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getUyeIletisimGunluk = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getiletisimgunluk',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const setUyeSistemeAktarildi = async (
  uyeId: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('servis', uyeId)
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/uyesistemeaktarildi?uyeId=' + uyeId,
    kln: kln,
  })
  console.log('result', result)
  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiUyeKaydet = async (item: IUye, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUye>({
    path: '/CenazeFonu/uyekaydet',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiAileFertKaydet = async (
  item: IAileFert,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IAileFert>({
    path: '/CenazeFonu/ailefertkaydet',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUyeNotlari = async (uyeId: string, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyenotlari?uyeId=' + uyeId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUyeIdealBilgisi = async (uyeId: string, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyeideallink?uyeId=' + uyeId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiUyeNotKaydet = async (
  item: IUyeNot,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyeNot>({
    path: '/CenazeFonu/uyenotkaydet',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiAileSil = async (
  item: IAileSil,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IAileSil>({
    path: '/CenazeFonu/ailesil',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiAileReisiSilKaydet = async (
  item: IAileReisiSil,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IAileReisiSil>({
    path: '/CenazeFonu/ailereisisilkaydet',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiVefatKaydet = async (
  item: IUyeVefat,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyeVefat>({
    path: '/CenazeFonu/vefatkaydet',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUyeFaturalari = async (
  uyeId: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyefaturalari?uyeId=' + uyeId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiGetBankaBicKodu = async (
  bankaKodu: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getbankabickodu?bankaKodu=' + bankaKodu,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiGetUyeBaslangicUcreti = async (
  id: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyebaslangicucreti?id=' + id,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiGetUyeMasrafUcreti = async (
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyemasrafucreti',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiParaAktar = async (
  item: IParaAktar,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IParaAktar>({
    path: '/CenazeFonu/parapaylas',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiUyeFaturaKaydet = async (
  item: IUyeFatura,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyeFatura>({
    path: '/CenazeFonu/uyefaturakaydet',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getPostaKodu = async (
  postaKodu: string,
  evNo:string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log("pkodu",postaKodu)
  if (postaKodu=='')
  {
    console.log("boş1")
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getpostakodu?postakodu=' + postaKodu+'&evno='+evNo,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUyeIletisimleri = async (
  uyeId: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyeiletisim?uyeId=' + uyeId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiUyeIletisimKaydet = async (
  item: IUyeIletisim,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyeIletisim>({
    path: '/CenazeFonu/uyeiletisimkaydet',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiUyeArama = async (
  item: IAramaSartlari,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IAramaSartlari>({
    path: '/CenazeFonu/uyearama',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getRapor21Yas = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getrapor21yas',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getAktiflikSuresiBekleyen = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getaktifliksuresibekleyen',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const get21YasUyumsuzlar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getrapor21yasuyumsuzlar',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getFaturaOdemeRapor = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getfaturaodemerapor',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getHatirlatmaRapor = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/gethatirlatmarapor',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const get21YasKaydolmayanlar = async (
  yil: number,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getrapor21yaskaydolmayanlar?yil=' + yil.toString(),
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getIbanSorgu = async (
  iban: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getibansorgu?iban=' + iban,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getUyeFaturaOdemeRapor = async (
  uyeNo: String,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyefaturaodemerapor?uyeNo=' + uyeNo,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}


export const apiUyeAktiflestir = async (
  uyeId: String,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/uyeaktiflestir?uyeId=' + uyeId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiAileAktiflestir = async (
  uyeNo: String,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/aileaktiflestir?uyeNo=' + uyeNo,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}


export const getMektupExcel = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getmektupexcel',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUyeSayisal = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyesayisal',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUyeBelgeleri = async (
  uyeId: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyebelgeleri?uyeId=' + uyeId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiGetDosyaDeneme = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getdosyadeneme',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiFileUpload = async (
  item: IUyeBelgeDosyali,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('api öncesi')
  console.log(item)
  /*const result = await http<DataFromServer, IUyeBelge>({
    path: '/CenazeFonu/fileupload',
    method: 'post',
    body: item,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }*/
  return {
    Data: {},
    Success: false,
    Message: '',
  }
}

export const apiUyeBelgeGuncelle = async (
  item: IUyeBelge,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyeBelge>({
    path: '/CenazeFonu/uyebelgeguncelle',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiTopluFaturaOlustur = async (
  item: ITopluFatura,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, ITopluFatura>({
    path: '/CenazeFonu/toplufaturaolustur',
    method: 'post',
    body: item,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiIncassoOlusturOnizleme = async (
  item: IIncassoYapim,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IIncassoYapim>({
    path: '/CenazeFonu/incassoolusturonizleme',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiIncassoOlustur = async (
  item: IIncassoYapim,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IIncassoYapim>({
    path: '/CenazeFonu/incassoolustur',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getIncassoLst = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getincassolst',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getGeriDonenOdemeLst = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getgeridonenodemeler',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getFazlaOdemeLst = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getfazlaodemeler',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}



export const apiKisiIncassoOlustur = async (
  item: IIncassoYapim,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IIncassoYapim>({
    path: '/CenazeFonu/kisiincassoolustur',
    method: 'post',
    body: item,
    kln
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getSepaSatirlar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getSepaSatirlar',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiSepaSatirSil = async (
  item: string[],
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, string[]>({
    path: '/CenazeFonu/sepasatirsil',
    method: 'post',
    body: item,
    kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiUyeNoIsle = async (item: IUyeNoIsle[], kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyeNoIsle[]>({
    path: '/CenazeFonu/uyenoisle',
    method: 'post',
    body: item,
    kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiSepaIsle = async (item: string[], kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, string[]>({
    path: '/CenazeFonu/sepaisle',
    method: 'post',
    body: item,
    kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiGetSepaHepIsle = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getSepaHepIsle',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
