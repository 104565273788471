import {
  IUlke,
  ISilmeNedeni,
  IUyruk,
  IAkrabaTuru,
  IFaturaCesidi,
  IBanka,
  IUyeBelgeTuru,
  ISehir,
  IUye,
  IAileFert,
  IUyeNot,
  IUyeIletisim,
  IUyeFatura,
  IUyeOzet,
  IAramaSonucItem,
  IAramaSartlari,
  IUyeBelge,
  IUyeBelgeDosyali,
  IIncassoYapim,
  IIncassoYapimItemDto,
  ISepaCsvDosyali,
  IAileSil,
} from './Interfaceler'
import { IKullanici } from './Interfaceler/IKullanici'
import { IKullaniciSifre } from './Interfaceler/IKullaniciSifre'
import { IParaAktar } from './Interfaceler/IParaAktar'
import { ITopluFatura } from './Interfaceler/ITopluFatura'

export const ulkeInitValues: IUlke = {
  Aktif: true,
  HollandacaIsmi: '',
  TurkceIsmi: '',
  UlkeId: '',
  UlkeKodu: '',
  MuslumanMi: true,
}

export const silmeNedeniInitValues: ISilmeNedeni = {
  Aktif: true,
  SilmeAciklamasi: '',
  SilmeNedeni: '',
  SilmeNedenId: '',
}

export const uyrukInitValues: IUyruk = {
  Aktif: true,
  UyrukAdi: '',
  UyrukId: '',
}

export const akrabaTuruInitValues: IAkrabaTuru = {
  Aktif: true,
  UyelikYakinlikTuru: '',
  UyelikYakinlikTuruId: '',
  Aciklama: '',
}

export const faturaCesidiInitValues: IFaturaCesidi = {
  Aktif: true,
  FaturaCesidi: '',
  FaturaCesitId: '',
  Aciklama: '',
}

export const bankaInitValues: IBanka = {
  Aktif: true,
  BankaKodu: '',
  BankaBicKodu: '',
  BankaAdi: '',
  BankaId: '',
}

export const kullaniciInitValues: IKullanici = {
  SaltOku: true,
  Aktif: true,
  KullaniciAdi: '',
  KullaniciCesitId: '',
  KullaniciId: '',
  TamAdi: ''
}

export const kullaniciSifreInitValues: IKullaniciSifre = {
  KullaniciAdi: '',
  KullaniciId: '',
  EskiSifre: '',
  YeniSifre: '',
  YeniSifreTekrar: ''
}
export const uyeBelgeTuruInitValues: IUyeBelgeTuru = {
  Aktif: true,
  UyeBelgeTuru: '',
  Aciklama: '',
  UyeBelgeTurId: '',
}

export const sehirInitValues: ISehir = {
  Aktif: true,
  SehirAdi: '',
  SehirId: '',
  SehirKodu: '',
  UlkeAdi: '',
  UlkeId: '',
}

export const uyeInitValues: IUye = {
  Ad: '',
  AileFertSayisi: 0,
  AileReisiMi: false,
  AileReisiUyeId: '',
  AileReisininNesiYakinlikTurId: '',
  BICKodu: '',
  CepTel: '',
  CinsiyetAdi: '',
  CinsiyetId: '',
  DogumTar: '',
  EkNo: '',
  EpostaAdresi: '',
  EvAdresi: '',
  EvNo: '',
  EvTel: '',
  GeldigiUlkeAdi: '',
  GeldigiUlkeId: 'f180cd28-5083-4f4f-b53d-7217d92e4157',
  Gizli: false,
  IbanNo: '',
  Kenmerk: '',
  KizlikSoyadi: '',
  PostaKodu: '',
  SehirAd: '',
  //SehirId: '',
  SistemKayitKid: '',
  SistemKayitKullaniciAdi: '',
  SistemKayitZamani: '',
  Soyad: '',
  TurkiyeAdresi: '',
  UyeDurumId: '',
  UyeDurumu: '',
  UyeId: '',
  UyeNo: '',
  UyelikTar: '',
  UyelikYakinlikTuru: '',
  UyrukAdi: '',
  UyrukId: '05f47f78-217d-4a07-8245-7b2c119cd5df',
  YakiniTel: '',
  IbanAd: '',
  IbanSoyad: '',
  Aciklama: '',
  FormKabul: true,
  ReferansUyeNo: '',
  SilinmeTarihi: '',
  SilmeNedenId: '',
  SilmeNedeni: '',
  YakiniEpostaAdresi: '',
  SonIslemKid: '',
  SonIslemKullanici: '',
  SonIslemTar: '',
  YakiniAdSoyad: '',
  DogumYeri: '',
  OtomatikOdeme: false,
  MedeniHalAdi: '',
  MedeniHalId: '',
  OlumTar: '',
  IlkUyelikParaYatirmaTar: '',
  IlkUyelikYatanParaMiktari: '',
  SonOdemeYapilanIbanNo: '',
  BorcOdeme: ''
}

export const ailefertInitValues: IAileFert = {
  Ad: '',
  AileReisininNesiYakinlikTurId: '',
  CepTel: '',
  CinsiyetAdi: '',
  CinsiyetId: '',
  DogumTar: '',
  EpostaAdresi: '',
  SistemKayitKid: '',
  SistemKayitKullaniciAdi: '',
  SistemKayitZamani: '',
  Soyad: '',
  UyeDurumId: '3ce22534-00ec-4732-8161-df60a967a4f9',
  UyeDurumu: '',
  UyeId: '',
  UyeNo: '',
  UyelikTar: '',
  UyelikYakinlikTuru: '',
  Aciklama: '',
  SilinmeTarihi: '',
  SilmeNedenId: '',
  SilmeNedeni: '',
  UlkeAdi: '',
  UlkeId: '',
  Yas: '',
  SiraNo: 0,
  DogumYeri: '',
  SonIslemKid: '',
  SonIslemKullanici: '',
  SonIslemTar: '',
  MedeniHalId: '61a29fe5-25a4-4b04-9aa7-f0aa61d9364d',
  MedeniHalAdi: '',
  OlumTar: ''
}

export const uyeNotInitValues: IUyeNot = {
  AciklamayiEkleyenKid: '',
  EklenmeZamani: '',
  Icerik: '',
  UyeAciklamaId: '',
  UyeNo: '',
}

export const uyeIletisimInitValues: IUyeIletisim = {
  Icerik: '',
  IletisimYolId: '',
  IletisimYoluAd: '',
  IletisimYoluDetayBilgi: '',
  Konu: '',
  SistemeAktaranKid: '',
  AciklamayiEkleyenKid: '',
  UyeId: '',
  UyeIletisimId: '',
  GelisZamani: '',
}

export const paraAktarInitValues: IParaAktar = {
  IkinciUyeNo: '',
  Tutar: '',
  UyeFaturaId: '',
  UyeId: '',
  Aciklama: ''
}

export const uyeFaturaInitValues: IUyeFatura = {
  Aciklama: '',
  Ad: '',
  Belge: '',
  BetalingsKenmerk: '',
  Fatura_yili: '',
  FaturaCesidi: '',
  FaturaOlusturmaTar: '',
  SonKullaniciAdi: '',
  SonKulTar: '',
  Soyad: '',
  UyeNo: '',
  FaturaCesitId: 'afd2928b-264d-4903-8377-6cc66c1fe471',
  FaturaKenmark: '',
  FaturaNo: '',
  FaturaOlusturanKadi: '',
  FaturaOlusturanKid: '',
  FaturaTarihi: '',
  Tutar: '',
  UyeFaturaId: '',
  UyeId: '',
  Fatura_odedigi_tarih: '',
  FaturaTutar: '',
}

export const uyeOzetInitValues: IUyeOzet = {
  UyeNo: '',
  UyeId: '',
  Adi: '',
  Soyadi: '',
  DogumTar: '',
  UyelikTar: '',
  UyeDurumId: '',
  UyeDurumAdi: '',
  KaydedenKullanici:''
}

export const aramaSonucItemInitValues: IAramaSonucItem = {
  UyeNo: '',
  UyeId: '',
  Ad: '',
  Soyad: '',
  DogumTar: '',
  UyelikTar: '',
  UyeDurumu: '',
  Aciklama: '',
  AileReisiMi: false,
  CepTel: '',
  Cinsiyet: '',
  PostaKodu: '',
  Sehir: '',
  Yakinlik: '',
  IbanNo: '',
  Kenmerk: '',
}

export const aramaSartlariInitValues: IAramaSartlari = {
  Aciklama: '',
  Ad: '',
  CepTel: '',
  DogumTar: '',
  EPosta: '',
  PostaKodu: '',
  Soyad: '',
  UyeNo: '',
  IbanNo: '',
  Kenmerk: '',
  Sehir: '',
  Aktif: false,
  UyelikYakinlikTuruId: '9a65d116-5157-44ff-bd3f-5ea2785ef911',
  UyeDurumId:''
}

export const uyeBelgeInitValues: IUyeBelge = {
  Aciklama: '',
  Aktif: false,
  DosyaAdi: '',
  DosyaFormatAdi: '',
  DosyaFormatId: '',
  UyeAdSoyad: '',
  UyeBelgeId: '',
  UyeBelgeTurAdi: '',
  UyeBelgeTurId: '',
  UyeId: '',
  UyedenGelenIletisimId: '',
  YuklenmeZamani: '',
  YukleyenKadi: '',
  YukleyenKid: '',
}

export const uyeBelgeDosyaliInitValues: IUyeBelgeDosyali = {
  Aciklama: '',
  Aktif: false,
  DosyaAdi: '',
  DosyaFormatAdi: '',
  DosyaFormatId: '',
  UyeAdSoyad: '',
  UyeBelgeId: '',
  UyeBelgeTurAdi: '',
  UyeBelgeTurId: '',
  UyeId: '',
  UyedenGelenIletisimId: '',
  YuklenmeZamani: '',
  YukleyenKadi: '',
  YukleyenKid: '',
  Dosya: {} as File,
}

export const topluFaturaliInitValues: ITopluFatura = {
  FaturaCesitId: 'afd2928b-264d-4903-8377-6cc66c1fe471',
  FaturaNo: '',
  FaturaTutar: '',
  FaturaYil: (new Date().getFullYear() + 1).toString(),
  FaturaTarihi: '',
  SonOdemeTar: ''
}

export const incassoYapimliInitValues: IIncassoYapim = {
  BaslangicUyeNo: 0,
  BitisUyeNo: 0,
  OlusturanKid: '',
  ParaCekimTar: '',
  Aciklama: ''
}

export const IIncassoYapimItemDtoInitValues: IIncassoYapimItemDto = {
  BasUyeNo: 0,
  BitUyeNo: 0,
  DosyaAd: '',
  IncassoYapimId: '',
  IncassoYapimKadi: '',
  IncassoYapimKid: '',
  IncassoYapimZamani: '',
  KayitSayisi: '',
  ParaCekimTar: '',
  ToplamTutar: '',
}

export const sepaCsvDosyaliInitValues: ISepaCsvDosyali = {
  Aciklama: '',
  SepaCsvId: '',
  YuklenmeZamani: '',
  YukleyenKadi: '',
  YukleyenKid: '',
  Dosya: {} as File,
}


export const AileSilInitValues: IAileSil = {
  Aciklama: '',
  UyeId: '',
  SilmeNedenId: ''
}