import {http} from './http'

import {DataFromServer, KullaniciDto} from '../lModel/Modeller'

export const getDashboardData = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/dashboardgetir',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
