/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { AppState } from '../../../../aStore/Store'
import { useSelector } from 'react-redux'
import { getYetkiler } from '../../../../lServis/AyarlarServisi'

export function AsideMenuMain() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const kullaniciDataData = useSelector((state: AppState) => state.kullaniciVerisi)
  const [yetkilerGeldi, setYetkilerGeldi] = useState(false)
  const [kaydediliyor, setKaydediliyor] = useState(false)
  const [kaydedildi, setKaydedildi] = useState(false)
  const [yetkilerArr, setYetkilerArr] = useState([] as String[])

  const yetkilerArrGetir = async () => {
    setLoading(true)
    const obj = await getYetkiler(kullaniciDataData.kullaniciData)
    setLoading(false)
    //console.log('obj', obj)
    const itemArr = obj.Data as String[]
    //console.log('yetkiler', itemArr)
    if (!obj.Success) {
      //alert(obj.Message)
    } else {
      setYetkilerArr(itemArr)
      setYetkilerGeldi(true)
    }
  }

  useEffect(() => {
     
    const doYetkilerArrGetir = async () => {
      yetkilerArrGetir()
    }

    
    doYetkilerArrGetir()
  }, [])
  
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Ana Sayfa'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/crafted/pages/profile'
        icon='/media/icons/duotune/coding/cod010.svg'
        title='Üye Profili'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CENAZE FONU</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/cenazefonu/uyeislemleri'
        title='Üye İşlemleri'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItemWithSub
          to='/cenazefonu/uyeislemleri/uyesurecleri'
          title='Üye Süreçleri'
          hasBullet={true}
        >
          {/* <AsideMenuItem
            to='/cenazefonu/uyeislemleri/uyesurecleri/yenibasvuru'
            title='Yeni Başvuru'
            hasBullet={true}
          /> */}
          
          {
            yetkilerGeldi && yetkilerArr.includes("c50aa2f2-73a3-4131-aafd-f5ed97d75892") ? (
              <AsideMenuItem
              to='/cenazefonu/uyeislemleri/uyesurecleri/vefat'
              title='Vefat'
              hasBullet={true}
            />
            ) :
            (
            <></> 
            )

          }
          
          {
            yetkilerGeldi && yetkilerArr.includes("978c773b-3ab6-438e-81bc-6357ace13df1") ? (
            <AsideMenuItem
            to='/cenazefonu/uyeislemleri/uyesurecleri/ailereisisilme'
            title='Aile Reisi Sil'
            hasBullet={true}
             />
            ) :
            (
            <></> 
            )

          }
          
          {/* <AsideMenuItem
            to='/cenazefonu/uyeislemleri/uyesurecleri/_21yasislemleri'
            title='21 Yaş'
            hasBullet={true}
          /> */}
          {/* <AsideMenuItem
            to='/cenazefonu/uyeislemleri/uyesurecleri/hatirlatmaislemleri'
            title='Hatırlatma'
            hasBullet={true}
          /> */}
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to='/cenazefonu/uyeislemleri/faturalandirma'
          title='Faturalandırma'
          hasBullet={true}
        >
          
          {
            yetkilerGeldi && yetkilerArr.includes("fa8b1e8c-7ba0-43e3-8ce7-13aae13fdd19") ? (
              <AsideMenuItem
              to='/cenazefonu/uyeislemleri/faturalandirma/otomatikfaturalandirma'
              title='Otomatik'
              hasBullet={true}
            />
            ) :
            (
            <></> 
            )

          }
          
          
          {
            yetkilerGeldi && yetkilerArr.includes("2deb4b86-93e5-489c-928c-ffc259d3e518") ? (
              <AsideMenuItem
            to='/cenazefonu/uyeislemleri/faturalandirma/manuelfaturalandirma'
            title='Manuel'
            hasBullet={true}
          />
            ) :
            (
            <></> 
            )

          }
          

        </AsideMenuItemWithSub>

        {/*<AsideMenuItemWithSub
          to='/cenazefonu/uyeislemleri/mektupislemleri'
          title='Mektup'
          hasBullet={true}
        >*/}
          {/* <AsideMenuItem
            to='/cenazefonu/uyeislemleri/mektupislemleri/basimislemleri'
            title='Basım'
            hasBullet={true}
          /> */}
          {/* <AsideMenuItem
            to='/cenazefonu/uyeislemleri/mektupislemleri/gonderimislemleri'
            title='Gönderim'
            hasBullet={true}
          /> */}
          {/*  <AsideMenuItem
            to='/cenazefonu/uyeislemleri/mektupislemleri/iadeislemleri'
            title='İade İşlemleri'
            hasBullet={true}
          /> */}
       {/* </AsideMenuItemWithSub>*/}

        {/*<AsideMenuItemWithSub
          to='/cenazefonu/uyeislemleri/kartislemleri'
          title='Kart'
          hasBullet={true}
        >*/}
          {/* <AsideMenuItem
            to='/cenazefonu/uyeislemleri/kartislemleri/basimislemleri'
            title='Basım İşlemleri'
            hasBullet={true}
          /> */}
          {/* <AsideMenuItem
            to='/cenazefonu/uyeislemleri/kartislemleri/gonderimislemleri'
            title='Gönderim İşlemleri'
            hasBullet={true}
          /> */}
       {/*    <AsideMenuItem
            to='/cenazefonu/uyeislemleri/kartislemleri/iadeislemleri'
            title='İade İşlemleri'
            hasBullet={true}
          /> */}
        {/*</AsideMenuItemWithSub>*/}

      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/cenazefonu/bankaislemleri'
        title='Banka İşlemleri'
        icon='/media/icons/duotune/finance/fin009.svg'
        fontIcon='bi-person'
      >
        
        {
            yetkilerGeldi && yetkilerArr.includes("986380c4-be32-4cdf-892b-734cdc639412") ? (
              <AsideMenuItem
          to='/cenazefonu/bankaislemleri/sepaislemleri'
          title='Sepa'
          hasBullet={true}
        />
            ) :
            (
            <></> 
            )

          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("4a5199d1-c9ef-48b4-9853-a9f6b1e41c44") ? (
              <AsideMenuItem
          to='/cenazefonu/bankaislemleri/incassoislemleri'
          title='Incasso'
          hasBullet={true}
        />
            ) :
            (
            <></> 
            )

          }
        
       {/*  <AsideMenuItem
          to='/cenazefonu/bankaislemleri/geridonenodemeler'
          title='Geri Dönen Ödemeler'
          hasBullet={true}
        /> */}
        {/* <AsideMenuItem
          to='/cenazefonu/bankaislemleri/fazlaodemeler'
          title='Fazla Ödemeler'
          hasBullet={true}
        /> */}
        
        {
            yetkilerGeldi && yetkilerArr.includes("c5028d9e-cd7b-45df-b7c1-5bb99cb9fec3") ? (
              <AsideMenuItem
          to='/cenazefonu/bankaislemleri/topluideal'
          title='Toplu Ideal Listesi'
          hasBullet={true}
        />
            ) :
            (
            <></> 
            )

          }
        
        {/* <AsideMenuItem
          to='/cenazefonu/bankaislemleri/acceptgiroislemleri'
          title='Acceptgiro İşlemleri'
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/cenazefonu/raporlar'
        title='Raporlar'
        icon='/media/icons/duotune/graphs/gra007.svg'
        fontIcon='bi-person'
      >
        
        {
            yetkilerGeldi && yetkilerArr.includes("ff22cb77-cdeb-405a-b7f3-112a3e0206bf") ? (
              <AsideMenuItem
          to='/cenazefonu/raporlar/odemeraporlari'
          title='Finans'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
        {/*  <AsideMenuItem
          to='/cenazefonu/raporlar/mektupraporlari'
          title='Mektup'
          hasBullet={true}
        /> */}
       {/*  <AsideMenuItem
          to='/cenazefonu/raporlar/separaporlari'
          title='Sepa'
          hasBullet={true}
        /> */}
       {/*  <AsideMenuItem
          to='/cenazefonu/raporlar/incassoraporlari'
          title='Incasso'
          hasBullet={true}
        /> */}

        {/*  <AsideMenuItem
          to='/cenazefonu/raporlar/uyesayisalbilgiler'
          title='Üye Sayısal Bilgiler'
          hasBullet={true}
        /> */}
        {/* <AsideMenuItem
          to='/cenazefonu/raporlar/faturaraporlari'
          title='Fatura Raporları'
          hasBullet={true}
        /> */}
        {/*  <AsideMenuItem
          to='/cenazefonu/raporlar/acceptgiroraporlari'
          title='Acceptgiro Raporları'
          hasBullet={true}
        /> */}

{
            yetkilerGeldi && yetkilerArr.includes("d8d05107-0e7c-45f9-9bec-792c07149428") ? (
              <AsideMenuItem
          to='/cenazefonu/raporlar/_21yasraporlari'
          title='21 Yaş Dolduranlar'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("4423e98d-44eb-42f9-81c1-74f2022b27b0") ? (
              <AsideMenuItem
          to='/cenazefonu/raporlar/_21yaskaydolmayanlar'
          title='21 Yaş Kaydolmayanlar'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("3a1f8b86-b2f7-477b-94bf-005949948780") ? (
              <AsideMenuItem
          to='/cenazefonu/raporlar/_21yasuyumsuzlar'
          title='21 Yaş Uyumsuzlar'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
        {/* <AsideMenuItem
          to='/cenazefonu/raporlar/kartbekleyenler'
          title='Kart Bekleyenler'
          hasBullet={true}
        /> */}
        
        {
            yetkilerGeldi && yetkilerArr.includes("69891083-48f6-4b6d-a09e-0d03b1522f7b") ? (
              <AsideMenuItem
            to='/cenazefonu/raporlar/aktifliksuresibekleyenler'
            title='Bekleyenler - İptal Edilenler'
            hasBullet={true}
          />
            ) :(<></> )
          }
        

       {/*  <AsideMenuItem
          to='/cenazefonu/raporlar/hatirlatmaraporlari'
          title='Hatırlatma'
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/cenazefonu/iletisim'
        title='İletişim'
        icon='/media/icons/duotune/communication/com012.svg'
        fontIcon='bi-person'
      >
        
        {
            yetkilerGeldi && yetkilerArr.includes("2a444f6d-d319-4ba1-a37b-32493094bc2e") ? (
              <AsideMenuItem
          to='/cenazefonu/iletisim/uyeiletisim'
          title='Üye Bilgi Notu'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/cenazefonu/ayarlar'
        title='Ayarlar'
        icon='/media/icons/duotune/general/gen019.svg'
        fontIcon='bi-person'
      >
        
        {
            yetkilerGeldi && yetkilerArr.includes("e7286a7b-721c-4db7-89b5-4c75f60caf85") ? (
              <AsideMenuItem
              to='/cenazefonu/ayarlar/belgeturleri'
              title='Belge Türleri'
              hasBullet={true}
            />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("c7ef8eae-918a-4874-adbd-e5cc8add2005") ? (
              <AsideMenuItem
              to='/cenazefonu/ayarlar/iletisimyollari'
              title='İletişim Yolları'
              hasBullet={true}
            />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("0ac723f5-192f-4745-9c37-717f2e9ce034") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/bankalar' title='Bankalar' hasBullet={true} />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("0040f9f9-1628-4da8-8ba2-864f6febc7d1") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/baslangicucretleri' title='Başlangıç Ücretleri' hasBullet={true} />
            ) :(<></> )
        }
        {
            yetkilerGeldi && yetkilerArr.includes("a7d61e9a-250e-4791-89f1-915a8fb93bd8") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/faturayili' title='Fatura Yili' hasBullet={true} />
            ) :(<></> )
        }
        {
            yetkilerGeldi && yetkilerArr.includes("8b82ac25-995e-4db5-b8b0-22cb78dafda2") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/yillikucretler' title='Yıllık Ücretler' hasBullet={true} />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("fde2119f-d88b-427e-bcd8-e1d158506712") ? (
              <AsideMenuItem
          to='/cenazefonu/ayarlar/akrabacesitleri'
          title='Akraba Çeşitleri'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("d71c0a21-7f06-4504-b224-703809435a52") ? (
              <AsideMenuItem
          to='/cenazefonu/ayarlar/faturacesitleri'
          title='Fatura Çeşitleri'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("567d60a4-3927-42dd-8d06-c4d0ca959ed4") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/kullanicilar' title='Kullanıcılar' hasBullet={true} />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("67f2df85-59a0-40e7-b87e-7b7a7ff9a5d6") ? (
              <AsideMenuItem
          to='/cenazefonu/ayarlar/silmenedenleri'
          title='Silme Nedenleri'
          hasBullet={true}
        />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("22b9ef7e-80fb-4186-94e6-846aacacb637") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/uyruklar' title='Uyruklar' hasBullet={true} />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("23f835dc-2dd1-47ec-9c82-d458d6d96516") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/ulkeler' title='Ülkeler' hasBullet={true} />
            ) :(<></> )
          }
        
        {
            yetkilerGeldi && yetkilerArr.includes("44d58bbc-fe95-4ccd-a556-7f608372e45f") ? (
              <AsideMenuItem to='/cenazefonu/ayarlar/sehirler' title='Şehirler' hasBullet={true} />
            ) :(<></> )
          }
        
      </AsideMenuItemWithSub>

 {/*      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Vakıf BV</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/vakifbv/cenazeislemleri'
        title='Cenaze İşlemleri'
        icon='/media/icons/duotune/abstract/abs027.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to='/vakifbv/cenazeislemleri/isemirleri'
          title='İş Emirleri'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/vakifbv/cenazeislemleri/ucakislemleri'
          title='Uçak İşlemleri'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/vakifbv/cenazeislemleri/yikamaislemleri'
          title='Yıkama İşlemleri'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/vakifbv/cenazeislemleri/cenazenamazi'
          title='Cenaze Namazı'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/vakifbv/cenazeislemleri/ailebilgilendirme'
          title='Aile Bilgilendirme'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/vakifbv/cenazeislemleri/cenazemasraftakibi'
          title='Cenaze Masraf Takibi'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
 */}
       
     
      
     {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Yönetici</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/yonetici/genelayarlar'
        title='Genel Ayarlar'
        icon='/media/icons/duotune/general/gen019.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to='/yonetici/genelayarlar/kullanicilar'
          title='Kullanıcılar'
          hasBullet={true}
        />
        <AsideMenuItem to='/yonetici/genelayarlar/islemler' title='İşlemler' hasBullet={true} />
        <AsideMenuItem to='/yonetici/genelayarlar/yetkiler' title='Yetkiler' hasBullet={true} />
        <AsideMenuItem to='/yonetici/genelayarlar/loglar' title='Loglar' hasBullet={true} />
        <AsideMenuItem
          to='/yonetici/genelayarlar/mobilayarlari'
          title='Mobil Ayarları'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>*/}
      {/* <AsideMenuItem
        to='/cenazefonu/account/settings'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Üye Bilgisi'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/raporlar'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Raporlar'
        fontIcon='bi-app-indicator'
      /> */}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
