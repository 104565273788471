import axios from 'axios'
import {IIncassoYapim, ISepaCsvDosyali, IUyeBelgeDosyali} from '../lModel/Interfaceler'
import {DataFromServer, KullaniciDto} from '../lModel/Modeller'
import {webAPIUrl} from '../AppSettings'
import {saveAs} from 'file-saver'
import {AkrabaTuruEditPopup} from '../app/modules/cfayarlar/components/popuplar'
export const apiFileDownloadAxios = async (
  incassoYapimId: string,
  dosyaAdi: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('api öncesi axios')

  const result = await axios
    .get(webAPIUrl + '/CenazeFonu/getincassodosya?incassoYapimId=' + incassoYapimId, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*',
        tokenStr: kln.TokenStr,
        kullaniciId21: kln.KullaniciId21,
      },
      responseType: 'blob',
    })
    .then(function (res) {
      console.log('res', res)
      var blob = new Blob([res.data], {
        type: 'application/octec-stream',
      })

      saveAs(blob, dosyaAdi)
    })

  /*const result = await http<DataFromServer, IUyeBelge>({
      path: '/CenazeFonu/fileupload',
      method: 'post',
      body: item,
    })
  
    if (result.ok && result.body) {
      return result.body
    } else {
      return {
        Data: {},
        Success: false,
        Message: '',
      }
    }*/
  return {
    Data: {},
    Success: false,
    Message: '',
  }
}

export const apiFileUploadAxios = async (
  item: IUyeBelgeDosyali,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('api öncesi axios')
  console.log(item)
  // Create an object of formData
  const formData = new FormData()

  // Update the formData object
  formData.append('file', item.Dosya, item.Dosya.name)
  formData.append('uyeId', item.UyeId)
  formData.append('uyeBelgeTurId', item.UyeBelgeTurId)
  formData.append('aciklama', item.Aciklama)
  // Details of the uploaded file

  // Request made to the backend api
  // Send formData object
  const headers = {
    tokenStr: kln.TokenStr,
    kullaniciId21: kln.KullaniciId21,
  }
  const result = await axios.post(webAPIUrl + '/CenazeFonu/fileupload', formData, {headers})
  if (result.status === 200 && result.data) {
    console.log('result.data')
    console.log(result.data)
    return result.data
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
  /*const result = await http<DataFromServer, IUyeBelge>({
      path: '/CenazeFonu/fileupload',
      method: 'post',
      body: item,
    })
  
    if (result.ok && result.body) {
      return result.body
    } else {
      return {
        Data: {},
        Success: false,
        Message: '',
      }
    }*/
  return {
    Data: {},
    Success: false,
    Message: '',
  }
}
export const apiCsvFileUploadAxios = async (
  item: ISepaCsvDosyali,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('csv')
  console.log(item)
  // Create an object of formData
  const formData = new FormData()

  // Update the formData object
  formData.append('file', item.Dosya, item.Dosya.name)
  formData.append('aciklama', item.Aciklama)

  // Request made to the backend api
  // Send formData object
  const headers = {
    tokenStr: kln.TokenStr,
    kullaniciId21: kln.KullaniciId21,
  }
  const result = await axios.post(webAPIUrl + '/CenazeFonu/csvfileupload', formData, {headers})
  if (result.status === 200 && result.data) {
    console.log('result.data')
    console.log(result.data)
    return result.data
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
  /*const result = await http<DataFromServer, IUyeBelge>({
      path: '/CenazeFonu/fileupload',
      method: 'post',
      body: item,
    })
  
    if (result.ok && result.body) {
      return result.body
    } else {
      return {
        Data: {},
        Success: false,
        Message: '',
      }
    }*/
  return {
    Data: {},
    Success: false,
    Message: '',
  }
}
export const apiKisiFileDownloadAxios = async (
  item: IIncassoYapim,
  dosyaAdi: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('api öncesi axios')

  const result = await axios
    .post(webAPIUrl + '/CenazeFonu/kisiincassoolustur', item, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*',
        tokenStr: kln.TokenStr,
        kullaniciId21: kln.KullaniciId21,
      },
      responseType: 'blob',
    })
    .then(function (res) {
      console.log('res', res)
      var blob = new Blob([res.data], {
        type: 'application/octec-stream',
      })

      saveAs(blob, dosyaAdi)
    })

  return {
    Data: {},
    Success: true,
    Message: '',
  }
}



export const apiTopluIdealDownloadAxios = async (
  dosyaAdi: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('api öncesi axios')

  const result = await axios
    .get(webAPIUrl + '/CenazeFonu/gettopluideal',  {
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*',
        tokenStr: kln.TokenStr,
        kullaniciId21: kln.KullaniciId21,
      },
      responseType: 'blob',
    })
    .then(function (res) {
      console.log('res', res)
      var blob = new Blob([res.data], {
        type: 'application/octec-stream',
      })

      saveAs(blob, dosyaAdi)
    })

  return {
    Data: {},
    Success: true,
    Message: '',
  }
}

export const apiBuYil21YasDolduranlarDownloadAxios = async (
  dosyaAdi: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('api öncesi axios')

  const result = await axios
    .get(webAPIUrl + '/CenazeFonu/getrapor21yasdolacaklar',  {
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*',
        tokenStr: kln.TokenStr,
        kullaniciId21: kln.KullaniciId21,
      },
      responseType: 'blob',
    })
    .then(function (res) {
      console.log('res', res)
      var blob = new Blob([res.data], {
        type: 'application/octec-stream',
      })

      saveAs(blob, dosyaAdi)
    })

  return {
    Data: {},
    Success: true,
    Message: '',
  }
}