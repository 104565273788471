import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {MyPage} from '../pages/MyPage'
import {Raporlar} from '../pages/Raporlar'
import {UyeBilgisi} from '../pages/UyeBilgisi'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const BankaIslemleriPage = lazy(() => import('../modules/bankaislemleri/BankaIslemleriPage'))
  const CenazeIslemleriPage = lazy(() => import('../modules/cenazeislemleri/CenazeIslemleriPage'))
  const CFAyarlarPage = lazy(() => import('../modules/cfayarlar/CFAyarlarPage'))
  const CFIletisimPage = lazy(() => import('../modules/cfiletisim/CFIletisimPage'))
  const CFRaporlarPage = lazy(() => import('../modules/cfraporlar/CFRaporlarPage'))
  const FaturalandirmaPage = lazy(() => import('../modules/faturalandirma/FaturalandirmaPage'))
  const GenelAyarlarPage = lazy(() => import('../modules/genelayarlar/GenelAyarlarPage'))
  const KartIslemleriPage = lazy(() => import('../modules/kartislemleri/KartIslemleriPage'))
  const MektupIslemleriPage = lazy(() => import('../modules/mektupislemleri/MektupIslemleriPage'))
  const UyeSurecleriPage = lazy(() => import('../modules/uyesurecleri/UyeSurecleriPage'))
  const VBVAyarlarPage = lazy(() => import('../modules/vbvayarlar/VBVAyarlarPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/my-page' component={MyPage} />
        <Route path='/raporlar' component={Raporlar} />
        <Route path='/uye-bilgisi' component={UyeBilgisi} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/cenazefonu/bankaislemleri' component={BankaIslemleriPage} />
        <Route path='/vakifbv/cenazeislemleri' component={CenazeIslemleriPage} />
        <Route path='/cenazefonu/ayarlar' component={CFAyarlarPage} />
        <Route path='/cenazefonu/iletisim' component={CFIletisimPage} />
        <Route path='/cenazefonu/raporlar' component={CFRaporlarPage} />
        <Route path='/cenazefonu/uyeislemleri/faturalandirma' component={FaturalandirmaPage} />
        <Route path='/yonetici/genelayarlar' component={GenelAyarlarPage} />
        <Route path='/cenazefonu/uyeislemleri/kartislemleri' component={KartIslemleriPage} />
        <Route path='/cenazefonu/uyeislemleri/mektupislemleri' component={MektupIslemleriPage} />
        <Route path='/cenazefonu/uyeislemleri/uyesurecleri' component={UyeSurecleriPage} />
        <Route path='/vakifbv/ayarlar' component={VBVAyarlarPage} />

        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/crafted/pages/profile/overview' />
        <Redirect exact from='/' to='/crafted/pages/profile/overview' />
        {/*<Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />*/}
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
