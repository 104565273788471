import {
  IUlke,
  ISilmeNedeni,
  IUyruk,
  IAkrabaTuru,
  IFaturaCesidi,
  IBanka,
  IUyeBelgeTuru,
  ISehir,
} from '../lModel/Interfaceler'
import { IBaslangicUcreti } from '../lModel/Interfaceler/IBaslangicUcreti'
import { IFaturaYili } from '../lModel/Interfaceler/IFaturaYili'
import { IKullanici } from '../lModel/Interfaceler/IKullanici'
import { IKullaniciSayfaYetki } from '../lModel/Interfaceler/IKullaniciSayfaYetki'
import { IKullaniciSayfaYetkiKaydet } from '../lModel/Interfaceler/IKullaniciSayfaYetkiKaydet'
import { IKullaniciSifre } from '../lModel/Interfaceler/IKullaniciSifre'
import { IYillikUcret } from '../lModel/Interfaceler/IYillikUcret'
import {DataFromServer, KullaniciDto} from '../lModel/Modeller'
import {http} from './http'

export const apiUlkeKaydet = async (ulke: IUlke, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUlke>({
    path: '/CenazeFonu/ulkekaydet',
    method: 'post',
    body: ulke,
    kln: kln,
  })
  console.log('result', result)
  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getUlkeler = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getulkeler',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getGenelAyarlar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getgenelayarlar',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
//getSilmeNedenleri
export const getSilmeNedenleri = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getsilmenedenleri',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiSilmeNedeniKaydet = async (
  item: ISilmeNedeni,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, ISilmeNedeni>({
    path: '/CenazeFonu/silmenedenikaydet',
    method: 'post',
    body: item,
    kln: kln,
  })
  console.log('result', result)
  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
//getUyruklar
export const getUyruklar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyruklar',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiUyrukKaydet = async (item: IUyruk, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyruk>({
    path: '/CenazeFonu/uyrukkaydet',
    method: 'post',
    body: item,
    kln: kln,
  })
  //console.log('result', result)
  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
//getIletisimYollari
export const getIletisimYollari = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getiletisimyollari',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
//getAkrabaTurleri
export const getAkrabaTurleri = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getakrabaturleri',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getKullaniciSayfaYetkiler = async (
  kullaniciId: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getkullanicisayfayetkiler?kullaniciId=' + kullaniciId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getMedeniHaller = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getmedenihaller',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiAkrabaTuruKaydet = async (
  item: IAkrabaTuru,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IAkrabaTuru>({
    path: '/CenazeFonu/akrabaturukaydet',
    method: 'post',
    body: item,
    kln: kln,
  })
  //console.log('result', result)
  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

//getAkrabaTurleri
export const getFaturaCesitleri = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getfaturacesitleri',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiFaturaCesidiKaydet = async (
  item: IFaturaCesidi,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IFaturaCesidi>({
    path: '/CenazeFonu/faturacesidikaydet',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

//getBankalar
export const getBankalar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getbankalar',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getKullanicilar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getkullanicilar',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getYillikUcretler = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getyillikucretler',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

//getBaslangicUcretleri
export const getBaslangicUcretleri = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getbaslangicucretleri',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getFaturaYili = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getfaturayili',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiBaslangicUcretleriIsle = async (item: IBaslangicUcreti[], kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IBaslangicUcreti[]>({
    path: '/CenazeFonu/baslangicucretiisle',
    method: 'post',
    body: item,
    kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiYillikUcretleriIsle = async (item: IYillikUcret[], kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IYillikUcret[]>({
    path: '/CenazeFonu/yillikucretisle',
    method: 'post',
    body: item,
    kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiYillikUcretOlustur = async ( kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/yillikucretolustur',
    method: 'post',
    kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiBankaKaydet = async (item: IBanka, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IBanka>({
    path: '/CenazeFonu/bankakaydet',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiFaturaYiliKaydet = async (item: IFaturaYili, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IFaturaYili>({
    path: '/CenazeFonu/faturayilikaydet',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiKullaniciKaydet = async (item: IKullanici, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IKullanici>({
    path: '/CenazeFonu/kullanicikaydet',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiKullaniciYetkiKaydet = async (item: IKullaniciSayfaYetkiKaydet[], kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IKullaniciSayfaYetkiKaydet[]>({
    path: '/CenazeFonu/kullanicisayfayetkikaydet',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiKullaniciSifreSifirla = async (item: IKullanici, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IKullanici>({
    path: '/CenazeFonu/kullanicisifresifirla',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const apiSifreDegistir = async (item: IKullaniciSifre, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IKullaniciSifre>({
    path: '/CenazeFonu/sifredegistir',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
//getuyebelgeturleri
export const getUyeBelgeTurleri = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyebelgeturleri',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getKullaniciCesitleri = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getkullanicicesitleri',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}


export const getYetkiler = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getyetkiler',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}


export const apiUyeBelgeTuruKaydet = async (
  item: IUyeBelgeTuru,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer, IUyeBelgeTuru>({
    path: '/CenazeFonu/uyebelgeturlerikaydet',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

//getsehirler
export const getSehirler = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getsehirler',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const getSehirlerUlkeid = async (
  ulkeId: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getsehirlerulkeid?ulkeId=' + ulkeId,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
export const apiSehirKaydet = async (item: ISehir, kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer, ISehir>({
    path: '/CenazeFonu/sehirkaydet',
    method: 'post',
    body: item,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

//getuyedurumlari
export const getUyeDurumlari = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/CenazeFonu/getuyedurumlari',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
