import {Field, FormikProvider, useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap-v5'
import * as Yup from 'yup'
import {useSelector} from 'react-redux'
import {AppState} from '../../../../../aStore/Store'
import {toast} from 'react-toastify'
import { IKullanici } from '../../../../../lModel/Interfaceler/IKullanici'
import { apiKullaniciKaydet, apiSifreDegistir, getKullaniciCesitleri } from '../../../../../lServis/AyarlarServisi'
import { IKullaniciCesidi } from '../../../../../lModel/Interfaceler/IKullaniciCesidi'
import { IKullaniciSifre,   } from '../../../../../lModel/Interfaceler/IKullaniciSifre'
import {kullaniciSifreInitValues as initialValues} from '../../../../../lModel/InitValues'

const vSchema = Yup.object().shape({
  EskiSifre: Yup.string().required('Gerekli'),
  YeniSifre: Yup.string().required('Gerekli'),
  YeniSifreTekrar: Yup.string().required('Gerekli').oneOf([Yup.ref('YeniSifre')], 'Şifreler uyuşmuyor.'),
})
interface IPropData {
  show: boolean
  title: string
  body: string
  data?: IKullanici
  onClick(snc: string): any
  onHide(snc: string): any
}
export function SifreDegistir(props: IPropData) {
  const [loading, setLoading] = useState(false)
  const [kaydediliyor, setKaydediliyor] = useState(false)
  const [kaydedildi, setKaydedildi] = useState(false)
  const klnData = useSelector((state: AppState) => state.kullaniciVerisi)
  const formik = useFormik<IKullaniciSifre>({
    initialValues,
    enableReinitialize: true,
    validationSchema: vSchema,
    onSubmit: async (values, {resetForm}) => {
      const itemObj: IKullaniciSifre = {
        YeniSifre: values.YeniSifreTekrar,
        YeniSifreTekrar: values.YeniSifreTekrar,
        EskiSifre: values.EskiSifre,
        KullaniciAdi: '',
        KullaniciId: klnData.kullaniciData.KullaniciId21,
      }
//console.log("submt")
      const snc = await ItemKaydet(itemObj)
      if (snc) resetForm()
    },
  })

  
  const ItemKaydet = async (itemData: IKullaniciSifre): Promise<boolean> => {
    setKaydediliyor(true)
    setKaydedildi(false)
    const obj = await apiSifreDegistir(itemData, klnData.kullaniciData)
    setKaydediliyor(false)
    if (!obj.Success) {
      setKaydedildi(false)
      alert(obj.Message)
      return false
    } else {
      setKaydedildi(true)
      toast.success('Şifre değiştirildi.')
      props.onClick('1')
      return true
    }
  }
  
  
  return (
    <div>
      <Modal size='lg' show={props.show} onHide={() => props.onHide('0')}>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
          <button type='button' className='btn-close' onClick={() => props.onHide('0')}></button>
        </Modal.Header>

        <Modal.Body>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} noValidate className='form'  autoComplete='off'>
              <div className='card-body p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Eski Şifre</label>

                  <div className='col-lg-8 fv-row'>
                    <Field
                      type='password'
                      name='EskiSifre'
                      placeholder='Eski Şifre'
                      className='form-control form-control-lg form-control-solid'
                    />
                    {formik.touched.EskiSifre && formik.errors.EskiSifre && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block kirmizi'>{formik.errors.EskiSifre}</div>
                      </div>
                    )}
                  </div>
                </div>

                

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Yeni Şifre</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <Field
                      type='password'
                      name='YeniSifre'
                       
                      placeholder='Yeni Şifre'
                      className='form-control form-control-lg form-control-solid'
                    />
                    {formik.touched.YeniSifre && formik.errors.YeniSifre && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block kirmizi'>{formik.errors.YeniSifre}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Yeni Şifre(Tekrar)</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <Field
                      type='password'
                      name='YeniSifreTekrar'
                      placeholder='Yeni Şifre(Tekrar)'
                      className='form-control form-control-lg form-control-solid'
                    />
                    {formik.touched.YeniSifreTekrar && formik.errors.YeniSifreTekrar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block kirmizi'>{formik.errors.YeniSifreTekrar}</div>
                      </div>
                    )}
                  </div>
                </div>
               
                


              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <Button
                  type='submit'
                  variant='primary'
                  disabled={kaydediliyor}
                  /* onClick={() => props.onClick('1')} */
                >
                  {!kaydediliyor && 'Değiştir'}
                  {kaydediliyor && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Lütfen bekleyiniz...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </div>
            </form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </div>
  )
}
