import {Store, createStore, combineReducers} from 'redux'
import {IAramaSartlari, IAramaSonucItem, IUye} from '../lModel/Interfaceler'
import {DashboardDtoVM, KullaniciDto, UyeDto} from '../lModel/Modeller'

interface KullaniciState {
  readonly kullaniciData: KullaniciDto
}
interface AramaState {
  readonly aramaSartlarData: IAramaSartlari
  readonly aramaSonucData: IAramaSonucItem[]
}
interface UyeState {
  readonly uyeData: UyeDto
}
interface UyeTemelState {
  readonly uyeTemelData: IUye
}
interface DashboardState {
  readonly dashboardData: DashboardDtoVM
}
export interface AppState {
  readonly kullaniciVerisi: KullaniciState
  readonly aramaVerisi: AramaState
  readonly uyeVerisi: UyeState
  readonly uyeTemelVerisi: UyeTemelState
  readonly dashboardVerisi: DashboardState
}

const initialDashboardState: DashboardState = {
  dashboardData: {
    BugunKaydolanSayisi: 0,
    BugunSaatlikSayi: [],
    HaftaGunlukSayi: [],
    KaydolanSayisi: 0,
    KaydolanSonUyeler: [],
    TiklayanSayisi: 0,
    TiklayipKaydolmayanSayisi: 0,
    ToplamKaydolmayanSayisi: 0,
    SistemeAktarilanSayisi: 0,
  },
}

const initialKullaniciState: KullaniciState = {
  kullaniciData: {
    KullaniciId21: '',
    TamAdi: '',
    TokenStr: '',
    ResimYolu: '',
    GirisYapti: false,
  },
}
const initialAramaState: AramaState = {
  aramaSartlarData: {
    Aciklama: '', 
    Ad: '',
    CepTel: '',
    DogumTar: '',
    EPosta: '',
    PostaKodu: '',
    Soyad: '',
    UyeNo: '',
    IbanNo: '',
    Kenmerk: '',
    Sehir:'',
    Aktif:false,
    UyelikYakinlikTuruId:'9a65d116-5157-44ff-bd3f-5ea2785ef911',
    UyeDurumId:''//'3ce22534-00ec-4732-8161-df60a967a4f9'
  },
  aramaSonucData: [],
}
const initialUyeTemelState: UyeTemelState = {
  uyeTemelData: {
    Ad: '',
    AileFertSayisi: 0,
    AileReisiMi: false,
    AileReisiUyeId: '',
    AileReisininNesiYakinlikTurId: '',
    BICKodu: '',
    CepTel: '',
    CinsiyetAdi: '',
    CinsiyetId: '',
    DogumTar: '',
    EkNo: '',
    EpostaAdresi: '',
    EvAdresi: '',
    EvNo: '',
    EvTel: '',
    GeldigiUlkeAdi: '',
    GeldigiUlkeId: '',
    Gizli: false,
    IbanNo: '',
    IbanAd: '',
    IbanSoyad: '',
    Kenmerk: '',
    KizlikSoyadi: '',
    PostaKodu: '',
    SehirAd: '',
    //SehirId: '',
    SistemKayitKid: '',
    SistemKayitKullaniciAdi: '',
    SistemKayitZamani: '',
    Soyad: '',
    TurkiyeAdresi: '',
    UyeDurumId: '',
    UyeDurumu: '',
    UyeId: '',
    UyeNo: '',
    UyelikTar: '',
    UyelikYakinlikTuru: '',
    UyrukAdi: '',
    UyrukId: '',
    YakiniTel: '',
    Aciklama: '',
    FormKabul: true,
    ReferansUyeNo: '',
    SilinmeTarihi: '',
    SilmeNedenId: '',
    SilmeNedeni: '',
    YakiniEpostaAdresi: '',
    SonIslemKid: '',
    SonIslemKullanici: '',
    SonIslemTar: '',
    YakiniAdSoyad:'',
    DogumYeri:'',
    OtomatikOdeme:false,
    MedeniHalAdi:'',
    MedeniHalId:'',
    OlumTar:'',
    IlkUyelikParaYatirmaTar:'',
    IlkUyelikYatanParaMiktari:'',
    SonOdemeYapilanIbanNo:'',
    BorcOdeme:''
  },
}
const initialUyeState: UyeState = {
  uyeData: {
    Ad: '',
    Iban: '',
    Notu: '',
    Soyad: '',
    Telefon: '',
    AileEposta: '',
    AileIsim: '',
    Eposta: '',
    IslemAdresi: '',
    KayitTar: '',
    SonTiklanmaTar: '',
    UyeNo: '',
    Adres: '',
    AileTelefon: '',
    DogumYeri: '',
    NLSehir: '',
    PostaKodu: '',
    TRSehir: '',
    SistemeAktarilmaTar: '',
  },
}

export const SETUYEDATA = 'SetUyeData'
export const SETUYETEMELDATA = 'SetUyeTemelData'
export const SETKULLANICIDATA = 'SetKullaniciData'
export const SETARAMADATA = 'SetAramaData'
export const SETDASHBOARDDATA = 'SetDashboardData'

export const setDashboardDataAction = (dashboardData: DashboardDtoVM) =>
  ({
    type: SETDASHBOARDDATA,
    dashboardData: dashboardData,
  } as const)

export const setUyeDataAction = (uyeData: UyeDto) =>
  ({
    type: SETUYEDATA,
    uyeData: uyeData,
  } as const)
export const setUyeTemelDataAction = (uyeTemelData: IUye) =>
  ({
    type: SETUYETEMELDATA,
    uyeTemelData: uyeTemelData,
  } as const)
export const setKullaniciDataAction = (kullaniciData: KullaniciDto) =>
  ({
    type: SETKULLANICIDATA,
    kullaniciData: kullaniciData,
  } as const)
export const setAramaDataAction = (aramaData: IAramaSartlari, aramaSncData: IAramaSonucItem[]) =>
  ({
    type: SETARAMADATA,
    aramaSartlarData: aramaData,
    aramaSonucData: aramaSncData,
  } as const)

type KullaniciActions = ReturnType<typeof setKullaniciDataAction>
type AramaActions = ReturnType<typeof setAramaDataAction>
type UyeActions = ReturnType<typeof setUyeDataAction>
type UyeTemelActions = ReturnType<typeof setUyeTemelDataAction>
type DashboardActions = ReturnType<typeof setDashboardDataAction>

const dashboardDataReducer = (state = initialDashboardState, action: DashboardActions) => {
  switch (action.type) {
    case SETDASHBOARDDATA: {
      return {
        ...state,
        dashboardData: action.dashboardData,
      }
    }
  }

  return state
}

const uyeTemelDataReducer = (state = initialUyeTemelState, action: UyeTemelActions) => {
  switch (action.type) {
    case SETUYETEMELDATA: {
      return {
        ...state,
        uyeTemelData: action.uyeTemelData,
      }
    }
  }

  return state
}

const uyeDataReducer = (state = initialUyeState, action: UyeActions) => {
  switch (action.type) {
    case SETUYEDATA: {
      return {
        ...state,
        uyeData: action.uyeData,
      }
    }
  }

  return state
}

const kullaniciDataReducer = (state = initialKullaniciState, action: KullaniciActions) => {
  console.log('store', action.kullaniciData)
  switch (action.type) {
    case SETKULLANICIDATA: {
      return {
        ...state,
        kullaniciData: action.kullaniciData,
      }
    }
  }

  return state
}
const aramaDataReducer = (state = initialAramaState, action: AramaActions) => {
  console.log('store', action)
  switch (action.type) {
    case SETARAMADATA: {
      return {
        ...state,
        aramaSartlarData: action.aramaSartlarData,
        aramaSonucData: action.aramaSonucData,
      }
    }
  }

  return state
}
const rootReducer = combineReducers<AppState>({
  kullaniciVerisi: kullaniciDataReducer,
  aramaVerisi: aramaDataReducer,
  uyeVerisi: uyeDataReducer,
  uyeTemelVerisi: uyeTemelDataReducer,
  dashboardVerisi: dashboardDataReducer,
})

export function configureStore(): Store<AppState> {
  const store = createStore(rootReducer, undefined)
  return store
}
