/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppState, setDashboardDataAction} from '../../../../aStore/Store'
import {IDashboard} from '../../../../lModel/Interfaceler'

import {getDashboardData} from '../../../../lServis'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  items?: number
}

const ListsWidget4: React.FC<Props> = ({className, items = 6}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState(null as unknown as IDashboard)
  //const dashboardData = useSelector((state: AppState) => state.dashboardVerisi)
  const klnData = useSelector((state: AppState) => state.kullaniciVerisi)
  const Yenile = async () => {
    setLoading(true)
    const obj = await getDashboardData(klnData.kullaniciData)
    setLoading(false)
    console.log('obj', obj)
    const dt = {...obj.Data} as IDashboard
    console.log('dashboardData', dt)
    if (!obj.Success) {
      //alert(obj.Message)
    } else {
      setDashboardData(dt)
      //dispatch(setDashboardDataAction(dashboardData))
    }
  }
  useEffect(() => {
    const doDashboardGetir = async () => {
      Yenile()
    }
    doDashboardGetir()
    //setInterval(Yenile, 300000)
  }, [])
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark'>Genel Sayılar</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Son güncel sayılar</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            disabled={loading}
            onClick={Yenile}
          >
            {!loading && (
              <KTSVG path='/media/icons/duotune/arrows/arr029.svg' className='svg-icon-2' />
            )}
            {loading && (
              <KTSVG path='/media/icons/duotune/general/gen012.svg' className='svg-icon-2' />
            )}
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                Toplam Aktif Üye
              </a>
              <span className='text-muted fw-bold d-block fs-7'> </span>
            </div>
            <span className='badge badge-light fw-bolder my-2'>
              {dashboardData?.KaydolanSayisi}
            </span>
          </div>
          {/* end::Section */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                Toplam Aile
              </a>
              <span className='text-muted fw-bold d-block fs-7'> </span>
            </div>
            <span className='badge badge-light fw-bolder my-2'>
              {dashboardData?.ToplamAileSayisi}
            </span>
          </div>
          {/* end::Section */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                Bu Yıl Kaydolan
              </a>
              <span className='text-muted fw-bold d-block fs-7'> </span>
            </div>
            <span className='badge badge-light fw-bolder my-2'>
              {dashboardData?.BuYilKaydolanSayisi}
            </span>
          </div>
          {/* end::Section */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                Bu Yıl Silinen
              </a>
              <span className='text-muted fw-bold d-block fs-7'> </span>
            </div>
            <span className='badge badge-light fw-bolder my-2'>
              {dashboardData?.BuYilSilinenSayisi}
            </span>
          </div>
          {/* end::Section */}
        </div>
        {/* end::Item */}

        {items > 4 && (
          <div className='d-flex align-items-sm-center mb-7'>
            {/* begin::Symbol */}
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <img
                  src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                  Bu Yıl Vefat
                </a>
                <span className='text-muted fw-bold d-block fs-7'> </span>
              </div>
              <span className='badge badge-light fw-bolder my-2'>
                {dashboardData?.BuYilVefatSayisi}
              </span>
            </div>
            {/* end::Section */}
          </div>
        )}

        {items > 5 && (
          <div className='d-flex align-items-sm-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <img
                  src={toAbsoluteUrl('/media/svg/brand-logos/fox-hub.svg')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                  Fox Broker App
                </a>
                <span className='text-muted fw-bold d-block fs-7'> </span>
              </div>
              <span className='badge badge-light fw-bolder my-2'>0</span>
            </div>
            {/* end::Section */}
          </div>
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget4}
