/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {AppState} from '../../../../aStore/Store'

import {toAbsoluteUrl} from '../../../helpers'
import {Languages} from './Languages'
import { SifreDegistir } from '../../../../app/modules/cfayarlar/components/popuplar/SifreDegistir'
import { Button } from 'react-bootstrap-v5'

const HeaderUserMenu: FC = () => {
  const klnData = useSelector((state: AppState) => state.kullaniciVerisi)
  const user: UserModel = {} as UserModel
  const [sifreDegistirGoster, setSifreDegistirGoster] = useState(false)

  const handleYeniShow = () => {
    setSifreDegistirGoster(true)
  }
   
  const handleYeniClose = (msg: string) => {
    setSifreDegistirGoster(false)
  }

  const handleYeniClick = (msg: string) => {
    setSifreDegistirGoster(false)
    //if (msg === '1') ItemArrGetir()
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {klnData.kullaniciData.TamAdi}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'>
      <SifreDegistir
          show={sifreDegistirGoster}
          title={'Şifre Değiştir'}
          body={''}

          onClick={handleYeniClick}
          onHide={handleYeniClose} 
          data={undefined}            />
      </div>

      <div className='separator my-2'>
      
      </div>
      <div className='menu-item px-10'>
      <Button variant='link' onClick={handleYeniShow}  >
            Şifre Değiştir
          </Button>
        
      </div>
      <div className='menu-item px-5'>
       
        <Link to='/logout' className='menu-link px-5'>
          Çıkış
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
