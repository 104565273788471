/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {AppState} from '../../../../aStore/Store'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
}

const TablesWidget10: React.FC<Props> = ({className}) => {
  const dashboardData = useSelector((state: AppState) => state.dashboardVerisi)

  const trItems = dashboardData.dashboardData.KaydolanSonUyeler.map((uye) => (
    <tr key={uye.UyeNo}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input className='form-check-input widget-9-check' type='checkbox' value='1' />
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <Link
              to={`crafted/account/settings/${uye.UyeNo}`}
              className='text-dark fw-bolder text-hover-primary fs-6'
            >
              <span>{uye.AdSoyad}</span>
            </Link>
            <span className='text-muted fw-bold text-muted d-block fs-7'> </span>
          </div>
        </div>
      </td>
      <td>
        <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>{uye.Tel}</span>
        <span className='text-muted fw-bold text-muted d-block fs-7'>{uye.Eposta}</span>
      </td>
      <td>
        <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>{uye.UyeNo}</span>
      </td>
      <td>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <Link
            to={`crafted/account/settings/${uye.UyeNo}`}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </Link>
        </div>
      </td>
    </tr>
  ))

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Yeni Kayıtlar</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Son Kaydolanlar</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Yeni Üye
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Üyeler</th>
                <th className='min-w-140px'>İletişim</th>
                <th className='min-w-120px'>Üye No</th>
               {/*  <th className='min-w-100px text-end'>İşlemler</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>{trItems}</tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget10}
