import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img src={toAbsoluteUrl('/media/logos/logo192.png')} alt='' />
      <span>Yükleniyor ...</span>
    </div>
  )
}
